<script setup lang="ts">
import { DEBUG_MENU } from '@/config'
import { VSonner } from 'vuetify-sonner'
import favicon from '@themeImages/icons/favicon.png'

onMounted(() => {
  const head = window.document.querySelector('head') as HTMLHeadElement
  head.insertAdjacentHTML('beforeend', `<link rel="icon" type="image/png" href="${favicon}" />`)
})
</script>
<template>
  <VApp class="position-relative">
    <div v-if="DEBUG_MENU" class="position-absolute top-0 left-0 ma-1" style="opacity: 0.4; z-index: 10000">
      <DebugMenu />
    </div>
    <div>
      <RouterView />
    </div>
    <VSonner expand position="bottom-left" />
  </VApp>
</template>
,
