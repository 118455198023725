import { defineStore } from 'pinia'
import { User } from '@/interfaces/user'
import type { Project } from '@/interfaces/project'
import { WithWorkflowState } from '@/interfaces/withWorkflowState'
import { JSONLD } from '@/interfaces/jsonld'
import { ContentCollection } from '@/interfaces/contentCollection'
type SimpleRule = { action: string }
type SubjectRule<T> = { action: string; callback: (arg: T) => boolean }
type Rule<T> = SimpleRule | SubjectRule<T>

export const useAbilityStore = defineStore('Ability', () => {
  const rules: Rule<JSONLD>[] = []
  const registerRule = <T>(rule: Rule<T>) => {
    if (!rules.find(r => r.action === rule.action)) rules.push(rule)
  }
  const setRules = <T>(newRules?: Rule<T>[]) => {
    rules.splice(0, rules.length, ...(newRules || []))
  }

  function can(action: string, subject?: JSONLD) {
    const rule = rules.find(rule => rule.action === action)
    if (rule) {
      if ('callback' in rule && subject) {
        return rule.callback(subject)
      } else {
        if (!rule.action) return false
        return action === rule.action
      }
    }
    return false
  }
  function defineAbility(user?: User) {
    if (user) {
      resetRules()
      registerRule({ action: 'visitRegular' })
      registerRule({
        action: 'archiveProject',

        callback: (arg: WithWorkflowState<Project>) => {
          return arg.accessRights?.rights.includes('CAN_ARCHIVE')
        },
      })

      registerRule({
        action: 'viewProject',
        callback: (arg: WithWorkflowState<Project>) => {
          return arg.accessRights?.rights.includes('CAN_VIEW')
        },
      })
      registerRule({
        action: 'deleteProject',
        callback: (arg: WithWorkflowState<Project>) => {
          return arg.accessRights?.rights.includes('CAN_DELETE')
        },
      })
      registerRule({
        action: 'addParticipant',
        callback: (arg: WithWorkflowState<Project>) => {
          return arg.accessRights?.rights.includes('CAN_INVITE_TO_PROJECT')
        },
      })
      registerRule({
        action: 'editProject',
        callback: (arg: WithWorkflowState<Project>) => {
          return arg.accessRights?.rights.includes('CAN_EDIT')
        },
      })
      registerRule({
        action: 'editContentCollection',
        callback: (arg: WithWorkflowState<ContentCollection>) => {
          return arg.accessRights?.rights.includes('CAN_EDIT')
        },
      })
      registerRule({
        action: 'deleteContentCollection',
        callback: (arg: WithWorkflowState<ContentCollection>) => {
          return arg.accessRights?.rights.includes('CAN_DELETE')
        },
      })
      registerRule({
        action: 'createContentCollection',
        callback: (arg: WithWorkflowState<Project>) => {
          return arg.accessRights?.rights.includes('CAN_CREATE_CONTENT_COLLECTION')
        },
      })
      registerRule({
        action: 'createContent',
        callback: (arg: WithWorkflowState<ContentCollection>) => {
          console.log(arg)
          return arg.accessRights?.rights.includes('CAN_CREATE_CONTENT')
        },
      })
      registerRule({
        action: 'viewContent',
        callback: (arg: WithWorkflowState<Project>) => {
          return arg.accessRights?.rights.includes('CAN_VIEW')
        },
      })
      registerRule({
        action: 'deleteContent',
        callback: (arg: WithWorkflowState<ContentCollection>) => {
          return arg.accessRights?.rights.includes('CAN_DELETE')
        },
      })
      registerRule({
        action: 'editContent',
        callback: (arg: WithWorkflowState<ContentCollection>) => {
          return arg.accessRights?.rights.includes('CAN_EDIT')
        },
      })
      registerRule({
        action: 'reviewEditor',
        callback: (arg: WithWorkflowState<ContentCollection>) => {
          return arg.accessRights?.rights.includes('CAN_REVIEW_EDITOR')
        },
      })
      registerRule({
        action: 'writeEditor',
        callback: (arg: WithWorkflowState<ContentCollection>) => {
          return arg.accessRights?.rights.includes('CAN_WRITE_EDITOR')
        },
      })
      registerRule({
        action: 'proofReadEditor',
        callback: (arg: WithWorkflowState<ContentCollection>) => {
          return arg.accessRights?.rights.includes('CAN_PROOF_READ_EDITOR')
        },
      })
      if (user.needsPasswordChange) {
        registerRule({ action: 'passwordChange' })
      }
      if (user.roles?.includes('ROLE_ADMIN')) {
        registerRule({ action: 'visitAdmin' })
        registerRule({ action: 'editUsers' })
        registerRule({ action: 'createProjects' })
        registerRule({ action: 'deleteProjects' })
        registerRule({ action: 'editProjects' })
        registerRule({ action: 'addProjects' })
        registerRule({ action: 'addParticipants' })
        registerRule({ action: 'useDebug' })
      }
    } else {
      setDefaultRules()
    }
    return rules
  }
  function setDefaultRules() {
    setRules([{ action: 'visitUnauth' }])
  }
  function resetRules() {
    setRules()
  }
  setDefaultRules()

  return { can, defineAbility }
})
