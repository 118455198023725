import { ContentCollection } from '@/interfaces/contentCollection'
import { Content } from '@/interfaces/content'
import { ApiResponse, ApiResponses, JSONLD } from '@/interfaces/jsonld'
import { Participant } from '@/interfaces/participant'
import { ParticipantInvitation } from '@/interfaces/participantInvitation'
import { Project } from '@/interfaces/project'
import { ProjectDeadline } from '@/interfaces/projectDeadline'
import { Field, Section, Types, TypesArray, UserRoles } from '@/interfaces/types'
import { User } from '@/interfaces/user'
import { UserGroup } from '@/interfaces/userGroup'
import { WithWorkflowState } from '@/interfaces/withWorkflowState'
import { WorkflowState } from '@/interfaces/workflowState'
import { Notification } from '@/interfaces/notification'
import { WorkflowTask } from '@/interfaces/workflowTask'
import { ProjectDeadlineContentTypes } from '@interfaces/projectDeadlineContentTypes'
import { Upload } from '@/interfaces/upload'
import { ContentType } from '@/interfaces/contentType'
import { Role } from '@/interfaces/role'
import { UserCheckResetPasswordToken } from '@/interfaces/userCheckResetPasswordToken'

export function objectFactory<T extends JSONLD>(data: ApiResponses<T>): T[] {
  const objects: T[] = []
  if ('hydra:member' in data) {
    data['hydra:member'].forEach((element: ApiResponse<T>) => {
      objects.push(createObject(element))
    })
  } else {
    throw new Error('Invalid object type in objectFactory')
  }
  return objects
}

export function createObject<T extends JSONLD>(data: ApiResponse<JSONLD>): T {
  if (isUpload(data)) {
    return new Upload(data) as unknown as T
  } else if (isWithWorkflowState(data)) {
    return new WithWorkflowState(data) as unknown as T
  } else if (isParticipant(data)) {
    return new Participant(data) as unknown as T
  } else if (isParticipantInvitation(data)) {
    return new ParticipantInvitation(data) as unknown as T
  } else if (isUser(data)) {
    return new User(data) as unknown as T
  } else if (isProject(data)) {
    return new Project(data) as unknown as T
  } else if (isProjectDeadlineContentTypes(data)) {
    return new ProjectDeadlineContentTypes(data) as unknown as T
  } else if (isUserGroup(data)) {
    return new UserGroup(data) as unknown as T
  } else if (isContentCollection(data)) {
    return new ContentCollection(data) as unknown as T
  } else if (isContent(data)) {
    return new Content(data) as unknown as T
  } else if (isNotification(data)) {
    return new Notification(data) as unknown as T
  } else if (isWorkflowState(data)) {
    return new WorkflowState(data) as unknown as T
  } else if (isWithWorkflowTask(data)) {
    return new WorkflowTask(data) as unknown as T
  } else if (isContentType(data)) {
    return new ContentType(data) as unknown as T
  } else if (isRole(data)) {
    return new Role(data) as unknown as T
  } else if (isUserCheckResetPasswordToken(data)) {
    return new UserCheckResetPasswordToken(data) as unknown as T
  }
  throw new Error('Invalid object type in createObject')
}
export function resolveRoute(type: Types | string, id?: number): string {
  if (!TypesArray.includes(type as Types)) {
    throw new Error('Invalid object type in resolveRoute')
  }
  return '/api/' + pascalToSnakeCase(type) + 's' + (id ? '/' + id.toString() + '' : '')
}

function selectId(a: JSONLD) {
  if (a.id) return a.id
  if (a['@id']) return a['@id']
  throw new Error('No id found')
}
export function upsertObject<T extends JSONLD>(arr: Ref<T[]>, obj: T, what?: (a: T) => number | string) {
  if (!arr.value.length) {
    arr.value.push(obj)
    return true
  }
  if (!what) what = selectId

  const index = arr.value.findIndex(oldObj => what(obj) === what(oldObj))

  if (index === -1) {
    arr.value.push(obj)
    return true
  } else {
    arr.value[index] = obj
    return false
  }
}

export function printRole(role: UserRoles): string {
  switch (role) {
    case 'ROLE_USER':
      return 'User'
    case 'ROLE_ADMIN':
      return 'Administrator'
    case 'ROLE_PROJECT_MANAGER':
      return 'Project Manager'
    default:
      return 'Unknown Role'
  }
}
export function isUpload(object: JSONLD): object is Upload {
  return object['@type'] === 'Upload'
}

export function isParticipant(object: JSONLD): object is Participant {
  return object['@type'] === 'Participant'
}

export function isParticipantInvitation(object: JSONLD): object is ParticipantInvitation {
  return object['@type'] === 'ParticipantInvitation'
}

export function isUser(object: JSONLD): object is User {
  return object['@type'] === 'User'
}

export function isProject(object: JSONLD): object is Project {
  return object['@type'] === 'Project'
}

export function isUserGroup(object: JSONLD): object is UserGroup {
  return object['@type'] === 'UserGroup'
}

export function isWithWorkflowTask<T extends JSONLD>(object: JSONLD): object is WorkflowTask<T> {
  return object['@type'] === 'WorkflowTaskDto' || object['@type'] === 'WorkflowTask'
}
export function isWithWorkflowState<T extends JSONLD>(object: JSONLD): object is WithWorkflowState<T> {
  return object['@type'] === 'WithWorkflowStateDto' || object['@type'] === 'WithWorkflowState'
}

export function isProjectDeadline(object: JSONLD): object is ProjectDeadline {
  return object['@type'] === 'ProjectDeadline'
}
export function isProjectDeadlineContentTypes(object: JSONLD): object is ProjectDeadlineContentTypes {
  return object['@type'] === 'ProjectDeadlineContentTypesDto'
}

//eslint-disable-next-line
export function isJSONLD(object: any): object is ApiResponse<JSONLD> {
  return object?.['@type'] !== undefined
}

export function isWorkflowState(object: JSONLD): object is WorkflowState {
  return object['@type'] === 'WorkflowState'
}

export function isNotification(object: JSONLD): object is Notification {
  return object['@type'] === 'Notification'
}
export function isContentCollection(object: JSONLD): object is ContentCollection {
  return object['@type'] === 'ContentCollection'
}
export function isContent(object: JSONLD): object is Content {
  return object['@type'] === 'Content'
}
export function isContentType(object: JSONLD): object is ContentType {
  return object['@type'] === 'ContentTypeDto'
}
export function isRole(object: JSONLD): object is Role {
  return object['@type'] === 'RoleDto'
}
export function isUserCheckResetPasswordToken(object: JSONLD): object is UserCheckResetPasswordToken {
  return object['@type'] === 'UserCheckResetPasswordTokenDto'
}

export function stripJSONLDFields<T extends JSONLD>(data: Partial<T>): Omit<T, keyof JSONLD> {
  const excludedFields: (keyof JSONLD)[] = ['@context', '@type', '@id', 'id']

  for (const field of excludedFields) {
    delete data[field]
  }
  for (const [key, value] of Object.entries(data)) {
    if (typeof value === 'object' && value !== null && '@type' in value) {
      // @ts-ignore
      data[key] = stripJSONLDFields(value)
    }

    if (Array.isArray(value)) {
      //@ts-ignore
      //eslint-disable-next-line
      data[key] = value.map((v: any) => {
        if (typeof v === 'object' && value !== null && '@type' in v) {
          return stripJSONLDFields(v)
        }
        return v
      })
    }
  }
  return data as Omit<T, keyof JSONLD>
}
type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P]
}
//eslint-disable-next-line
export function getDifferences<T extends Record<string, any>>(obj1: T, obj2: T): DeepPartial<T> {
  const differences: DeepPartial<T> = {}

  //eslint-disable-next-line
  function compareObjects(a: any, b: any, result: any): void {
    for (const key in a) {
      if (!(key in b)) {
        result[key] = a[key]
      } else if (typeof a[key] === 'object' && a[key] !== null) {
        result[key] = {}
        compareObjects(a[key], b[key], result[key])
        if (Object.keys(result[key]).length === 0) {
          delete result[key]
        }
      } else if (!Object.is(a[key], b[key])) {
        result[key] = a[key]
      }
    }

    for (const key in b) {
      if (!(key in a)) {
        result[key] = b[key]
      }
    }
  }

  compareObjects(obj1, obj2, differences)
  return differences
}

export function flattenObject(obj: Record<string, Section>) {
  const flattened: Record<string, Field> = {}
  for (const key in obj) {
    const nestedEntries: Array<[string, Field]> = []
    if (obj[key].columns) {
      obj[key].columns.forEach(column => {
        nestedEntries.push(...Object.entries(column.fields))
      })
    }
    if (obj[key].fields) {
      nestedEntries.push(...Object.entries(obj[key].fields))
    }
    nestedEntries.forEach(entry => {
      if (entry[1].name) {
        flattened[entry[1].name] = entry[1]
      } else {
        flattened[`${key}:${entry[0]}`] = entry[1]
      }
    })
  }
  return flattened
}
export function getFieldFromFlat(obj: Record<string, Section>, flat: string) {
  const split = flat.split(':')

  if (split.length === 2) return obj[split[0]].fields[split[1]]
}
